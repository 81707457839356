import React, { useState } from "react"
import {
  Button,
  ButtonGroup,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Add, ArrowDropDown } from "@mui/icons-material"

import * as shared from "probuild-shared"
import CommonIcon from "./CommonIcon"

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}))

const MainCreateButton = ({
  text,
  menu,
  onButtonClicked,
  onMenuActionClicked,
}: {
  text: string
  menu: shared.com.probuildsoftware.probuild.library.common.data.view.MenuViewData | null
  onButtonClicked: () => void
  onMenuActionClicked: (
    action: shared.com.probuildsoftware.probuild.library.common.data.view.ActionViewData
  ) => void
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [moreAnchorElement, setMoreAnchorElement] =
    useState<null | HTMLElement>(null)
  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorElement(event.currentTarget)
  }
  const handleMoreClosed = () => {
    setMoreAnchorElement(null)
  }
  if (!menu) {
    return null
  }
  const actions = menu.actions.asJsReadonlyArrayView() || []
  return (
    <>
      {isSmallScreen ? (
        <Button
          variant="contained"
          aria-label={menu?.title}
          aria-expanded={moreAnchorElement ? "true" : undefined}
          aria-haspopup="menu"
          onClick={actions.length > 0 ? handleMoreClicked : onButtonClicked}
        >
          <Add />
        </Button>
      ) : (
        <ButtonGroup
          variant="contained"
          aria-label="split button"
          sx={{ flexShrink: 0 }}
        >
          <Button onClick={onButtonClicked}>{text}</Button>
          {actions.length > 0 && (
            <Button
              size="small"
              aria-label={menu?.title}
              aria-expanded={moreAnchorElement ? "true" : undefined}
              aria-haspopup="menu"
              onClick={handleMoreClicked}
            >
              <ArrowDropDown />
            </Button>
          )}
        </ButtonGroup>
      )}
      <Menu
        anchorEl={moreAnchorElement}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(moreAnchorElement)}
        onClick={handleMoreClosed}
        onClose={handleMoreClosed}
      >
        {isSmallScreen && (
          <>
            <StyledTypography variant="subtitle1">{text}</StyledTypography>
            <Divider />
          </>
        )}
        {actions.map((action) => (
          <MenuItem
            key={action.actionKey}
            onClick={() => {
              if (onMenuActionClicked) {
                onMenuActionClicked(action)
              }
              handleMoreClosed()
            }}
          >
            <ListItemIcon>
              <CommonIcon name={action.actionIcon} />
            </ListItemIcon>
            <ListItemText>{action.actionLabel}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MainCreateButton
