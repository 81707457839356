import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"

const LeftDiv = styled("div")(({ theme }) => ({
  width: "40%",
  height: "100%",
  backgroundColor: theme.palette.grey[200],
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxHeight: "70%",
  },
}))

const RightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "leading",
  width: "60%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    maxHeight: "30%",
    overflow: "scroll",
    alignItems: "flex-start",
  },
}))

const StyledBox = styled(Box)({
  textAlign: "left",
  whiteSpace: "pre-line",
})

const RootDiv = styled("div")(({ theme }) => ({
  height: "275px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))

const RepeatItemRow = ({
  documentAnswerKey,
  element,
  input,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const description = element.row?.photo?.photoDescription
  const descriptionFallback = element.row?.photo?.photoDescriptionFallback
  return (
    <RootDiv>
      <LeftDiv>
        <ImageView
          alt={element.row?.photo?.photoDescription || ""}
          file={element.row?.photo?.photoFileData}
          width={310}
          height={375}
          cover={true}
        />
      </LeftDiv>
      <RightDiv>
        {description && <StyledBox fontStyle="normal">{description}</StyledBox>}
        {descriptionFallback && (
          <StyledBox fontStyle="italic">{descriptionFallback}</StyledBox>
        )}
      </RightDiv>
    </RootDiv>
  )
}

export default RepeatItemRow
